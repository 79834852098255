header[data-testid='header_container'] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  z-index: 1000;
  color: #fff;
  background-color: #0a1a2d;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

div[data-testid='header_wrapper'] {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span[data-testid='header_logo_and_title'] {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

h1[data-testid='header_title'] {
  font-size: 2rem;
}

nav[data-testid='header_nav'] {
  display: flex;
  gap: 1.5rem;
}

a[data-testid='header_login_btn'] {
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  transition: transform 0.1s linear;
}

a[data-testid='header_login_btn']:hover {
  text-shadow: 0 0 10px #ffffff;
  transform: scale(1.15);
}

a[data-testid='header_login_btn']:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Media Queries */
@media screen and (max-width: 768px) {
  a[data-testid='header_login_btn'] {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  header[data-testid='header_container'] {
    padding: 1rem;
  }

  div[data-testid='header_wrapper'] {
    width: 95%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  span[data-testid='header_logo_and_title'] {
    column-gap: 1rem;
    align-self: flex-start;
  }

  a[data-testid='header_logo'] img {
    height: 2.5rem;
    min-width: 2.5rem;
  }

  h1[data-testid='header_title'] {
    font-size: 1.5rem;
  }

  nav[data-testid='header_nav'] {
    align-self: end;
  }

  a[data-testid='header_login_btn'] {
    font-size: 1rem;
  }
}
