li[data-testid^='bookList_item'] {
  width: 100%;
  min-height: 7rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  transition: transform 0.3s, background-color 0.3s ease;
}

li[data-testid*='bookList_item']:nth-child(even) {
  background-color: #f2f2f2;
}

li[data-testid^='bookList_item']:hover {
  background-color: #eaf3ff;
  transform: scale(1.005);
}

/* ----- */
span[data-testid='book_index'] {
  text-align: center;
  min-width: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
  color: #007bff;
}

div[data-testid='book_title_and_author_wrapper'] {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 1rem;
  overflow: hidden;
}

/* Title */
div[data-testid='book_title_wrapper'] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
}

span[data-testid='book_title'] {
  text-transform: capitalize;
  display: inline-block;
}

span[data-testid='book_title']::before,
span[data-testid='book_title']::after {
  content: '"';
}

/* Author */
div[data-testid='book_author_wrapper'] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span[data-testid='book_author'] {
  padding-left: 0.5rem;
  font-weight: bold;
  transition: color 0.2s ease text-shadow 0.2s ease;
}

span[data-testid='book_author']:hover {
  color: #007bff;
  text-shadow: 0px 0px 5px #99c6f5;
}

/* Action Buttons */
div[data-testid='book_actions'] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

button[data-testid*='book_favorite_toggle_'] {
  min-width: 0;
  height: 3.5rem;
  width: 3.5rem;
  padding: 0rem;
  margin: 0;
  border: 1px solid #fca510;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

button[data-testid*='book_favorite_toggle_false']:hover,
button[data-testid*='book_favorite_toggle_true']:hover {
  background-color: #f6dbabfd;
}

[data-testid='book_favorite_icon'],
[data-testid='book_nonFavorite_icon'] {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: #fca510;
  transition: transform 0.2s, color 0.2s ease;
}

[data-testid='book_favorite_icon']:hover,
[data-testid='book_nonFavorite_icon']:hover {
  transform: scale(1.1);
  color: #e69303;
}

/* Delete Button */
button[data-testid*='delete_book_btn'] {
  margin: 0;
  margin-right: 0.5rem;
  background-color: #fff;
  color: red;
  border: 1px solid red;
}

button[data-testid*='delete_book_btn']:hover {
  background-color: rgb(255, 204, 204);
}
