div[data-testid='filter_container'] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

div[data-testid='filter_title_wrapper'],
div[data-testid='filter_author_wrapper'] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 768px) {
  div[data-testid='filter_container'] {
    flex-direction: column;
    align-items: center;
  }

  div[data-testid='filter_title_wrapper'],
  div[data-testid='filter_author_wrapper'] {
    width: 80%;
    margin: 0.2rem 0;
  }
}
