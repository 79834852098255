div[data-testid='signInHelp_page'] {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

a[data-testid='signInHelp_back_btn'] {
  font-size: 2.5rem;
  color: #fff;
  display: inline-block;
  transition: transform 0.1s linear;
}

a[data-testid='signInHelp_back_btn']:hover {
  transform: scale(1.15);
}

form[data-testid='signInHelp_form'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  padding: 3rem;
  padding-bottom: 1rem;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
}

div[data-testid='signInHelp_form_username_wrapper'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2rem;
}

div[data-testid='signInHelp_form_links'] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

div[data-testid='signInHelp_form_links'] a {
  color: #000;
}

div[data-testid='signInHelp_form_links'] a:hover {
  text-decoration: underline;
}
