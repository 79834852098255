footer[data-testid='footer_container'] {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  z-index: 1000;
  color: #fff;
  background-color: #0a1a2d;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

div[data-testid='footer_wrapper'] {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
}

span[data-testid='footer_header_text'] {
  font-style: italic;
  font-weight: normal;
}

/* Name Link */
h2 a[data-testid='footer_header_name_link'] {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

h2 a[data-testid='footer_header_name_link']:hover {
  color: #a8cff7;
  transform: scale(1.02);
  text-shadow: 0px 0px 5px #007bff;
}

/* Navigation Links Container */
nav[data-testid='footer_nav_elements'] {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Navigation Links */
a[data-testid='footer_nav_element'] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  border-radius: 0.6rem;
  border: 0.1rem solid #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Icons */
img[data-testid^='footer_icon_'] {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 0.6rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

img[data-testid^='footer_icon_']:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px #fff;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  div[data-testid='footer_wrapper'] {
    width: 90%;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    gap: 0;
    font-size: 0.9rem;
  }

  a[data-testid='footer_nav_element'] {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 480px) {
  div[data-testid='footer_wrapper'] {
    width: 100%;
    font-size: 0.7rem;
    padding: 0.5rem;
  }

  a[data-testid='footer_nav_element'] {
    width: 2rem;
    height: 2rem;
  }
}
