[data-testid='app_main'] {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 80%;
  gap: 2rem;
  /* padding-top: 9rem; */
  margin: 9rem auto;
  font-size: 1.5rem;
}

[data-testid='app_left_column'],
[data-testid='app_right_column'] {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

[data-testid='app_left_column'] {
  width: 35%;
}

[data-testid='app_right_column'] {
  width: 65%;
}

[data-testid='search_book_section'],
[data-testid='manualAddBook_section'],
[data-testid='random_book_section'],
[data-testid='filters_section'],
[data-testid='bookList_section'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  [data-testid='app_main'] {
    width: 90%;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
  }

  [data-testid='app_left_column'],
  [data-testid='app_right_column'] {
    width: 100%;
    gap: 1rem;
  }

  [data-testid='search_book_section'],
  [data-testid='manualAddBook_section'],
  [data-testid='filters_section'],
  [data-testid='bookList_section'] {
    font-size: 1.2rem;
  }

  [data-testid='random_book_section'] {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  [data-testid='app_main'] {
    width: 95%;
  }

  [data-testid='search_book_section'],
  [data-testid='manualAddBook_section'],
  [data-testid='filters_section'],
  [data-testid='bookList_section'] {
    font-size: 1rem;
  }
}

/* 
.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  }
  
  .language-selector select:hover {
    background-color: #dbe4f8;
    }
    
    .language-selector select {
      background-color: #fff;
      color: #0a1a2d;
      border: none;
      padding: 8px 12px;
      border-radius: 3px;
      font-size: 12px;
      cursor: pointer;
      margin: 10px;
      transition: background-color 0.3s ease;
      }
      
      .language-selector select:focus {
        outline: none;
        border-color: #0a1a2d;
        } */
