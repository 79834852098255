form[data-testid='search_form'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

div[data-testid*='search_input_wrapper'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
