div[data-testid='modal_body'] {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 5rem;
  overflow-y: auto;
  gap: 1rem;
}

div[data-testid^='modal_book_item'] {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid #ddd;
  gap: 1rem;
  padding: 1rem;
}

div[data-testid='modal_book_content'] {
  width: 100%;
  display: flex;
  gap: 1rem;
}

div[data-testid='book_left_content'] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
}

img[data-testid='modal_book_img'] {
  min-width: 70%;
  max-height: 100%;
  padding-top: 3rem;
}

div[data-testid='book_right_content'] {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}

div[data-testid='modal_add_book_wrapper'] {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

button[data-testid*='modal_add_book_btn'] {
  margin: 0;
}
