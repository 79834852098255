button[data-testid^='componentButton'] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  min-width: 8rem;
  height: 3.5rem;
  border: none;
  padding: 0 1rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease,
    box-shadow 0.1s ease;
}

button[data-testid^='componentButton']:hover {
  transform: scale(1.03);
  background-color: #0056b3;
}

button[data-testid^='componentButton']:active {
  background-color: #016adb;
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button[data-testid^='componentButton']:disabled {
  background-color: darkgray;
  border: 1px solid darkgray;
  color: #fff;
  cursor: not-allowed;
}

span[data-testid='button_text'] {
  transition: transform 0.2s ease;
}

span[data-testid='button_text']:hover {
  transform: scale(1.05);
}

[data-testid='search_spinner'] {
  font-size: 1.5rem;
  animation: spinner 1s infinite linear;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
