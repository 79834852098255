div[data-testid='bookListModal_body'] {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 5rem;
  overflow-y: auto;
  gap: 1rem;
}

/* Book Item */
div[data-testid^='bookListModal_book_item'] {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid #ddd;
  gap: 1rem;
  padding: 1rem;
}

div[data-testid='bookListModal_book_content'] {
  width: 100%;
  display: flex;
  gap: 1rem;
}

div[data-testid='bookListModal_left_content'] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
}

img[data-testid='bookListModal_book_img'] {
  min-width: 70%;
  max-height: 100%;
  padding-top: 3rem;
}

div[data-testid='bookListModal_right_content'] {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}

div[data-testid='bookListModal_add_book_wrapper'] {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

/* Actions Wrapper */
div[data-testid='bookListModal_actions_wrapper'] {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

/* Favorite Toggle Button */
button[data-testid*='bookListModal_favorite_toggle_'] {
  min-width: 0;
  height: 3.5rem;
  width: 3.5rem;
  padding: 0;
  margin: 0;
  border: 1px solid #fca510;
  background-color: #fff;
}

button[data-testid*='bookListModal_favorite_toggle_false']:hover,
button[data-testid*='bookListModal_favorite_toggle_true']:hover {
  background-color: #f6dbab;
}

/* Favorite Icon */
[data-testid='bookListModal_favorite_icon'],
[data-testid='bookListModal_nonFavorite_icon'] {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: transform 0.2s, color 0.3s, ease;
  color: #fca510;
}

[data-testid='bookListModal_favorite_icon']:hover,
[data-testid='bookListModal_nonFavorite_icon']:hover {
  transform: scale(1.1);
  color: #e69303;
}

button[data-testid*='bookListModal_delete_btn'] {
  margin: 0;
  background-color: #fff;
  color: red;
  border: 1px solid red;
}

button[data-testid*='bookListModal_delete_btn']:hover {
  background-color: rgb(255, 204, 204);
}

button[data-testid*='bookListModal_delete_btn']:disabled {
  border: 1px solid darkgray;
  color: fff;
  background-color: darkgray;
}
