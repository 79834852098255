[data-testid='random_book_section'] {
  justify-content: center;
  overflow: hidden;
  height: 40rem;
  transition: background-color 0.5s ease-in-out;
}

[data-testid='random_book_img'] {
  margin: auto;
  height: 95%;
  width: auto;
  object-fit: contain;
  cursor: pointer;
  animation: zoomInOut 10s ease-in-out infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

[data-testid='random_book_section']:hover {
  background-color: #7db8f8;
}

[data-testid='random_book_section']:hover [data-testid='random_book_img'] {
  box-shadow: 0 0 40px 20px rgba(255, 255, 255, 0.8);
  transform: scale(1.2);
}

[data-testid='loading_spinner'] {
  font-size: 3.5rem;
  animation: spinner 1s infinite linear;
  color: #007bff;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
