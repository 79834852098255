form[data-testid='manualAddBook_form'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

div[data-testid*='manualAddBook_inputs_wrapper'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
