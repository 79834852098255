div[data-testid='modal_overlay'] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  z-index: 1000;
  padding-top: 9rem;
  backdrop-filter: blur(5px);
}

div[data-testid='modal_component'] {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-height: 90%;
  background-color: #f2f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1100;
}

div[data-testid='modal_header'] {
  display: flex;
  justify-content: flex-end;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  div[data-testid='modal_component'] {
    width: 95%;
    height: auto;
  }
}
